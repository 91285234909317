// achievements


.achievements {
  margin-left: 0;
  margin-top: 50px;

  [class^="col"] {
    margin-bottom: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .achievements {
    margin-left: 190px;
  }
}
