// challenge-steps

.challenge-steps {
  width: 100%;
  padding: 0px;
  margin: 30px auto 30px auto;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: table;
    width: 100%;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: table-cell;
      position: relative;
    }

    li:first-child {
      width: 30px;
    }

    li:first-child .step:before {
      content: none;
    }
  }

  .step {
    width: 30px;
    height: 30px;
    border: 2px solid #eee;
    border-radius: 50%;
    line-height: 37px;
    font-size: 15px;
    text-align: center;
    background-color: $white;
    float: right;
  }

  .step:before {
    height: 3px;
    display: block;
    background-color: #eee;
    position: absolute;
    content: "";
    border-radius: 5px;
    right: 45px;
    left: 10px;
    top: 50%;
  }

  /*ACTIVE*/
  .step.s-active {
    border-color: $primary;
    color: $primary;
    background: $white;
  }

  .step.s-active:before {
    background: $primary;
  }

  .step.s-active:after {
    color: $primary;
    position: absolute;
    content: "";
    top: 10px;
    right: 10px;
    height: 10px;
    width: 10px;
    background: $primary;
    border-radius: 50%;
  }

  /*RIGHT*/
  .step.s-right {
    background-color: $white;
    border-color: $success;
    color: $success;
  }

  .step.s-right:after {
    font-family: 'icomoon' !important;
    color: $success;
    position: absolute;
    content: "\e91c";
    top: 8px;
    right: 7px;
    border-radius: 50%;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  .step.s-right:before {
    background-color: $primary;
  }

  /*WRONG*/
  .step.s-wrong {
    background-color: $white;
    border-color: $danger;
    color: $danger;
  }

  .step.s-wrong:after {
    font-family: 'icomoon' !important;
    color: $danger;
    position: absolute;
    content: "\e922";
    top: 8px;
    right: 7px;
    border-radius: 50%;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }

  .step.s-wrong:before {
    background-color: $primary;
  }
}

@include media-breakpoint-up(lg) {
  .challenge-steps {
  }
}
