// cards card-sm

.card.card-sm {
  background-color: $primary;
  border: none;
  min-height: 108px;

  .card-body {
    padding: 8px 20px;

    .card-title {
      font-size: $title-xxs;
      line-height: $title-line-height-xxs;
      letter-spacing: $title-letter-spacing-xxs;
      font-weight: $font-weight-normal;
      font-style: normal;
      color: $white;
    }

    .battle-time {
      color: $white;

      [class^="icon-"],
      [class*=" icon-"] {
        margin-right: 5px;
      }
    }
  }
}

.card.card-sm.disable {
  opacity: 0.5;
}
