// checkbox-group

.checkbox-group {
  .checkbox-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    .checkbox-input {
      // Code to hide the input
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;

      &:checked+.checkbox-tile {
        border-color: $primary;
        box-shadow: $box-shadow-sm;
        color: $primary;

        &:before {
          transform: scale(1);
          opacity: 1;
          background-color: $primary;
          border-color: $primary;
        }

        .checkbox-icon,
        .checkbox-label {
          color: $primary;
        }
      }

      &:focus+.checkbox-tile {
        border-color: $primary;
        box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;

        &:before {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    .checkbox-tile {
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid $gray-500;
      background-color: $white;
      box-shadow: $box-shadow-sm;
      transition: 0.15s ease;
      cursor: pointer;
      position: relative;
      padding: 20px;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;

        background-color: $primary;
        border-radius: 50%;
        top: -13px;
        left: -13px;
        opacity: 0;
        transform: scale(0);
        transition: 0.25s ease;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }

      &:hover {
        border-color: $primary;

        &:before {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    .checkbox-icon {
      transition: .375s ease;
      color: #494949;

      svg {
        width: 3rem;
        height: 3rem;
      }
    }

    .checkbox-label {
      color: #707070;
      transition: .375s ease;
      text-align: center;
    }
  }
}

/*
  <fieldset class="checkbox-group">

    <div class="checkbox">
      <label class="checkbox-wrapper">
        <input type="checkbox" class="checkbox-input" />
        <span class="checkbox-tile">
          <span class="checkbox-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="currentColor" viewBox="0 0 256 256">
              <rect width="256" height="256" fill="none"></rect>
              <circle cx="96" cy="144.00002" r="10"></circle>
              <circle cx="160" cy="144.00002" r="10"></circle>
              <path d="M74.4017,80A175.32467,175.32467,0,0,1,128,72a175.32507,175.32507,0,0,1,53.59754,7.99971" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path>
              <path d="M181.59717,176.00041A175.32523,175.32523,0,0,1,128,184a175.32505,175.32505,0,0,1-53.59753-7.99971" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path>
              <path d="M155.04392,182.08789l12.02517,24.05047a7.96793,7.96793,0,0,0,8.99115,4.20919c24.53876-5.99927,45.69294-16.45908,61.10024-29.85086a8.05225,8.05225,0,0,0,2.47192-8.38971L205.65855,58.86074a8.02121,8.02121,0,0,0-4.62655-5.10908,175.85294,175.85294,0,0,0-29.66452-9.18289,8.01781,8.01781,0,0,0-9.31925,5.28642l-7.97318,23.91964" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path>
              <path d="M100.95624,182.08757l-12.02532,24.0508a7.96794,7.96794,0,0,1-8.99115,4.20918c-24.53866-5.99924-45.69277-16.459-61.10006-29.85069a8.05224,8.05224,0,0,1-2.47193-8.38972L50.34158,58.8607a8.0212,8.0212,0,0,1,4.62655-5.1091,175.85349,175.85349,0,0,1,29.66439-9.18283,8.0178,8.0178,0,0,1,9.31924,5.28642l7.97318,23.91964" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path>
            </svg>
          </span>
          <span class="checkbox-label">Discord</span>
        </span>
      </label>
    </div>

    <div class="checkbox">
      <label class="checkbox-wrapper">
        <input type="checkbox" class="checkbox-input" checked />
        <span class="checkbox-tile">
          <span class="checkbox-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="currentColor" viewBox="0 0 256 256">
              <rect width="256" height="256" fill="none"></rect>
              <polygon points="56 100 56 168 128 236 128 168 200 168 56 32 200 32 200 100 56 100" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></polygon>
            </svg>
          </span>
          <span class="checkbox-label">Framer</span>
        </span>
      </label>
    </div>

    <div class="checkbox">
      <label class="checkbox-wrapper">
        <input type="checkbox" class="checkbox-input" />
        <span class="checkbox-tile">
          <span class="checkbox-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="currentColor" viewBox="0 0 256 256">
              <rect width="256" height="256" fill="none"></rect>
              <polygon points="72 40 184 40 240 104 128 224 16 104 72 40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></polygon>
              <polygon points="177.091 104 128 224 78.909 104 128 40 177.091 104" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></polygon>
              <line x1="16" y1="104" x2="240" y2="104" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
            </svg>
          </span>
          <span class="checkbox-label">Sketch</span>
        </span>
      </label>
    </div>

    <div class="checkbox">
      <label class="checkbox-wrapper">
        <input type="checkbox" class="checkbox-input" />
        <span class="checkbox-tile">
          <span class="checkbox-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="currentColor" viewBox="0 0 256 256">
              <rect width="256" height="256" fill="none"></rect>
              <circle cx="128" cy="128" r="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></circle>
              <rect x="36" y="36" width="184" height="184" rx="48" stroke-width="12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></rect>
              <circle cx="180" cy="75.99998" r="10"></circle>
            </svg>
          </span>
          <span class="checkbox-label">Instagram</span>
        </span>
      </label>
    </div>

  </fieldset>
*/