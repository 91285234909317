// resumen

.resumen {
  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .question {
      margin-top: 30px;
      margin-bottom: 60px;
      position: relative;

      .question-tx {
        font-size: $title-xs;
        line-height: $title-line-height-xs;
        letter-spacing: $title-letter-spacing-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
        color: $secondary;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      input {
        color: $secondary;
        box-shadow: $box-shadow-sm;
        display: block;
        width: 100%;
        cursor: pointer;
        transition: all .15s ease-in-out;
        border: 1px solid $white;
        border-radius: $border-radius;
        background-color: $white;
        position: relative;
        padding: 20px;
        margin-bottom: 20px;
      }
    }

    .question.answer-right {

      fieldset {
        .form-label {
          color: $success;
        }

        input {
          border: 1px solid $success;
          background-color: rgba($success, 0.1);
        }
      }

      .answer-time {
        position: absolute;
        top: -30px;
        left: 38px;
      }
    }

    .question.answer-right:before {
      font-family: 'icomoon' !important;
      color: $success;
      position: absolute;
      content: "\e91c";
      top: -30px;
      left: auto;
      border-radius: 50%;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 2px solid $success;
      background-color: rgba($success, 0.1);
      padding: 3px;
    }

    .question.answer-wrong {

      fieldset {
        .form-label {
          color: $success;
        }

        input {
          border: 1px solid $success;
          background-color: rgba($success, 0.1);
        }
      }

      fieldset:last-child {
        .form-label {
          color: $danger;
        }

        input {
          border: 1px solid $danger;
          background-color: rgba($danger, 0.1);
        }
      }

      .answer-time {
        position: absolute;
        top: -30px;
        left: 38px;
      }
    }

    .question.answer-wrong:before {
      font-family: 'icomoon' !important;
      color: $danger;
      position: absolute;
      content: "\e922";
      top: -30px;
      left: auto;
      border-radius: 50%;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 2px solid $danger;
      background-color: rgba($danger, 0.1);
      padding: 3px;
    }

    /*players-right*/

    .question.player-1-right {     

      .answer-time.player-1 {
        position: absolute;
        top: -30px;
        left: 38px;
      }
    }

    .question.player-2-right {     

      .answer-time.player-2 {
        position: absolute;
        top: -30px;
        right: 38px;
      }
    }

    .question.player-1-right:before,
    .question.player-2-right:after {
      font-family: 'icomoon' !important;
      color: $success;
      position: absolute;
      content: "\e91c";
      top: -30px;
      border-radius: 50%;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 2px solid $success;
      background-color: rgba($success, 0.1);
      padding: 3px;
    }

    .question.player-1-right:before {
      left: 0;
    }

    .question.player-2-right:after {
      right: 0;
    }

    /*players-wrong*/

    .question.player-1-wrong {     

      .answer-time.player-1 {
        position: absolute;
        top: -30px;
        left: 38px;
        display: none;
      }
    }

    .question.player-2-wrong {     

      .answer-time.player-2 {
        position: absolute;
        top: -30px;
        right: 38px;
        display: none;
      }
    }

    .question.player-1-wrong:before,
    .question.player-2-wrong:after {
      font-family: 'icomoon' !important;
      color: $danger;
      position: absolute;
      content: "\e922";
      top: -30px;
      border-radius: 50%;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 2px solid $danger;
      background-color: rgba($danger, 0.1);
      padding: 3px;
    }

    .question.player-1-wrong:before {
      left: 0;
    }

    .question.player-2-wrong:after {
      right: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .resumen {
    .container {
      max-width: 680px;
      padding-left: 40px;
      padding-right: 40px;

      .question {

        .question-tx {
          font-size: $title-md;
          line-height: $title-line-height-md;
          letter-spacing: $title-letter-spacing-md;
          font-weight: $font-weight-normal;
          font-style: normal;
          color: $secondary;
        }
      }

      .question.answer-right {
        input {}
      }

      /*
      .question.answer-right:before {
      font-family: 'icomoon' !important;
      color: $success;
      position: absolute;
      content: "\e91c";
      top: 8px;
      left: -40px;
      border-radius: 50%;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 2px solid $success;
      background-color: rgba($success, 0.1);
      padding: 3px;
      }

      .question.answer-wrong:before {
      font-family: 'icomoon' !important;
      color: $danger;
      position: absolute;
      content: "\e922";
      top: 8px;
      left: -40px;
      border-radius: 50%;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 2px solid $danger;
      background-color: rgba($danger, 0.1);
      padding: 3px;
      }*/

    }
  }
}

// resumen-end

.resumen-end {
  display: flex;
  width: 100%;
  margin: 0 0 80px 0;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  a.btn,
  button.btn {
    margin: 0;
    padding: 0;
  }

  .btn+.btn {
    margin-right: 0;
  }
}

@include media-breakpoint-up(lg) {
  .resumen-end {
    text-align: inherit;
    flex-direction: row;
  }
}

// participant

.participant {
  color: $secondary;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  flex-direction: column;

  .img-profile {
    width: 60px;
    height: 60px;
    margin: 10px;
  }
}

// chatbox

.chatbox {
  padding: 0 0 20px 0;

  .chatter {
    color: $secondary;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    .img-profile {
      width: 32px;
      height: 32px;
      margin: 5px;
    }

    &.my-text {
      .text {
        text-align: left;
      }
    }

    &.your-text {
      flex-direction: row-reverse;
      justify-content: end;

      .text {
        text-align: right;
      }
    }
  }
}

// message

.message {
  text-align: center;

  .message-title {
    text-align: left;
    color: $gray-500;
    font-size: $title-xs;
    line-height: $title-line-height-xs;
    letter-spacing: $title-letter-spacing-xs;
    font-weight: $font-weight-normal;
    font-style: normal;
    margin-bottom: 10px;
  }

  .message-subtitle {
    text-align: left;
    color: $secondary;
    font-size: $title-sm;
    line-height: $title-line-height-sm;
    letter-spacing: $title-letter-spacing-sm;
    font-weight: $font-weight-normal;
    font-style: normal;
    margin-bottom: 40px;
  }

  textarea {
    margin-bottom: 30px;
  }

  .btn {
    width: 100%;
    padding: 18px 24px;
    font-size: 1.125rem;
    max-width: 380px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .message {
    .message-subtitle {
      font-size: $title-md;
      line-height: $title-line-height-md;
      letter-spacing: $title-letter-spacing-md;
      font-weight: $font-weight-normal;
      font-style: normal;
    }
  }
}
