// ranking-nav

.ranking-nav {
  display: block;

  .nav {
    margin: 0 0 20px 0;
    padding: 0;
    border-radius: 0;
    border-bottom: 1px solid $gray-200;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .nav-link {
      color: $gray-400;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin-right: 10px;
      padding: 0 0 20px 0;
      border: none;
      width: 48%;
    }

    .nav-link+.nav-link {
      margin-right: 0;
    }

    .nav-link.active {
      color: $primary;
      border-bottom: 3px solid $primary;
    }
  }
}

@include media-breakpoint-up(xl) {
  .ranking-nav {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  .ranking-nav {
    .nav {
      justify-content: start;

      .nav-link {
        margin-right: 20px;
        width: auto;
      }
    }
  }
}


// tab-content

.rankings {
  .tab-content>.tab-pane {
    .ranking-list {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .rankings {
    .tab-content {
      display: flex;
      justify-content: space-between;
    }

    .tab-content>.tab-pane {
      display: contents;

      .ranking-list {
        width: 48%;
      }
    }
  }
}
