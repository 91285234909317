// messages

.messages {
  margin-left: 0;
  margin-top: 0;

  [class^="col"] {
    margin-bottom: 20px;
  }

  .message-new {
    display: flex;
    justify-content: end;

    .btn {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .messages {}
}
