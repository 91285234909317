// card-result

.card-result {
  position: relative;
  background-color: $white;
  padding: 10px 20px;
  display: flex;
  border-radius: $border-radius;
  align-items: center;
  margin-bottom: 60px;

  img {
    width: 50px;
    height: 50px;
    margin: 10px 20px 10px 0;
  }

  .card-header-tx {
    .card-title {
      font-size: $title-xs;

      line-height: $title-line-height-xs;
      letter-spacing: $title-letter-spacing-xs;
      font-weight: $font-weight-normal;
      font-style: normal;
    }

    .card-text {
      font-size: $font-size-xs;
      line-height: $line-height-xs;
      font-weight: $font-weight-normal;
      font-style: normal;
    }
  }
}

.card-result:after {
  font-family: 'icomoon' !important;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  font-size: $font-size-xl; 
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.card-result.winner {
  border: 1px solid $success;
  background-color: rgba($success, 0.1);

  img {
    border: 1px solid $success;
  }

  .card-header-tx {
    .card-title {
      color: $success;
    }
  }
}

.card-result.winner:after {
  color: $success;
  content: "\e93e";
  /*border: 1px solid $success;*/
  background-color: rgba($success, 0.1);
}

.card-result.loser {
  border: 1px solid $danger;
  background-color: rgba($danger, 0.1);

  img {
    border: 1px solid $danger;
  }

  .card-header-tx {
    .card-title {
      color: $danger;
    }
  }
}

.card-result.loser:after {
  color: $danger;
  content: "\e93d";
  /*border: 1px solid $danger;*/
  background-color: rgba($danger, 0.1);
}

