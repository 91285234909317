// header-brand

.header-brand {

  .btn {
    text-align: left;
    color: $white;
    padding: 0;
    border: 0;
    width: 120px;
    height:28px;
    margin: 18px 20px;
  }

  a:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(lg) {
  .header-brand {

  .btn {
    width: 140px;
  }
  }
}
