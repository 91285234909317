// modal-conversacion

.modal-conversacion {
  .modal {
    .modal-dialog {
      .modal-content {

        .modal-header {}

        .modal-body {}

        .modal-footer {
          flex-direction: column;

          form {
            width: 100%;

            textarea {
              margin-bottom: 20px;
            }

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}


@include media-breakpoint-up(md) {
  .modal-conversacion {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-header {}

          .modal-footer {
            flex-direction: column;

            form {

              textarea {
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
}
