// user

.user {
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-normal;
  font-style: normal;
  padding: 0;

  .btn {
    display: flex;
    align-items: center;
    width: 100%;

    .img-profile {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .name {
      color: $secondary;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
    }
  }

  .btn-check:checked+.btn,
  :not(.btn-check)+.btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: #ecf3fc;
  }

  .btn:hover {
    background-color: #ecf3fc;
  }

  .btn:focus {
    background-color: #ecf3fc;
  }

  .btn:active {
    border-color: #ecf3fc;
  }
}



.profile-avatar {
  position: relative;
  width: 40px;
  height: 40px;

  .img-profile {
    width: 40px;
    height: 40px;
  }
}

.profile-avatar.on-line:after {
  color: $success;
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  height: 12px;
  width: 12px;
  background: $success;
  border-radius: 50%;
  display: block;
  border: 2px solid $white;
}


.profile-avatar.off-line:after {
  color: $danger;
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  height: 12px;
  width: 12px;
  background: $danger;
  border-radius: 50%;
  display: block;
  border: 2px solid $white;
}
