// swiper-historical

.swiper-historical {
  width: 100%;
  margin-bottom: 40px;
}

@include media-breakpoint-up(lg) {
  .swiper-historical {}
}

.swiper-historical:hover {

  .swiper-button-prev,
  .swiper-button-next {
    opacity: 1;
    visibility: visible;
  }

  .swiper-button-prev:before,
  .swiper-button-next:before {
    opacity: 0.7;
  }
}

.swiper-historical {
  position: relative;

  [class^="swiper-button-"] {
    width: 60px;
    height: 100%;
    top: 20px;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }

  .swiper-button-prev:before,
  .swiper-button-next:before {
    content: "";
    display: block;
    background-color: $secondary;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0.3;
    z-index: -1;
    transition: all .3s ease;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: $font-size-lg;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: $white;
  }

  .swiper-button-prev:after {
    content: "\e906";
  }

  .swiper-button-next:after {
    content: "\e907";
  }
}
