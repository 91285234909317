// multistep-form


.form-step {}

ul.form-stepper {
  counter-reset: section;
  width: 126px;
  margin: 0 auto;
  padding-left: 0;
  position: absolute;
  top: 24px;
  left: 50%;
  margin-left: -44px;
}

ul.form-stepper .form-stepper-circle {
  position: relative;
}

ul.form-stepper .form-stepper-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.form-stepper-horizontal {
  position: relative;
  display: flex;
  justify-content: space-between;
}

ul.form-stepper>li:not(:last-of-type) {}

.form-stepper-horizontal>li:not(:last-of-type) {}

.form-stepper-horizontal li {
  position: relative;
  display: flex;
  flex: 1;
  align-items: start;
  transition: 0.5s;
  height: 26px;
}

.form-stepper-horizontal li:not(:last-child):after {
  position: absolute;
  height: 26px;
  content: "";
  top: 0;
  left: 1px;
  z-index: 0;
  width: 88px;
  border-radius: 20px;
}

.form-stepper-horizontal li:nth-last-child(2):after {
  display: none;
}


.form-stepper-horizontal li:after {
  background-color: #ECF3FC;
}

.form-stepper-horizontal li.form-stepper-completed:after {
  background-color: $primary;
}

.form-stepper-horizontal li:last-child {
  flex: unset;
  display: none;
}

ul.form-stepper li a .form-stepper-circle {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 0;
  text-align: center;
  border-radius: 50%;
}

.form-stepper .form-stepper-active .form-stepper-circle {
  background-color: $primary !important;
  color: $white;
}

.form-stepper .form-stepper-active .form-stepper-circle:after {
  content: "";
  height: 26px;
  width: 26px;
  border: 8px solid rgba($primary, 1);
  background: $white;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  font-weight: 500;
  line-height: 25px;
}

.form-stepper .form-stepper-active .label {
  color: $primary !important;
}

.form-stepper .form-stepper-active .form-stepper-circle:hover {
  background-color: $primary !important;
  color: $white !important;
}

.form-stepper .form-stepper-unfinished .form-stepper-circle {
  background-color: #ECF3FC;
}

.form-stepper .form-stepper-unfinished .form-stepper-circle:after {
  content: "";
  height: 26px;
  width: 26px;
  border: 8px solid #ECF3FC;
  background: $white;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  font-weight: 500;
  line-height: 25px;
}

.form-stepper .form-stepper-completed .form-stepper-circle {
  background-color: $primary !important;
  color: $white;
}

.form-stepper .form-stepper-completed .form-stepper-circle:after {
  font-family: 'icomoon' !important;
  content: "\e91c";
  color: $white;
  display: block;
  position: relative;
  transition: 0.5s;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}

.form-stepper .form-stepper-completed .label {
  color: $primary !important;
}

.form-stepper .form-stepper-completed .form-stepper-circle:hover {
  background-color: $primary !important;
  color: $white !important;
}

.form-stepper .form-stepper-active span.text-muted {
  color: $white !important;
}

.form-stepper .form-stepper-completed span.text-muted {
  color: $white !important;
}

.form-stepper .label {}

.form-stepper a {
  cursor: default;
  z-index: 1;
}


@include media-breakpoint-up(md) {
  ul.form-stepper {
    top: 40px;
  }
}
