// statistics

.statistics {
  position: relative;

  select {
    margin-bottom: 40px;
    padding: 20px;
  }
}

.statistics-header {
  background-color: #ecf3fc;
  display: flex;
  border: none;
  padding: 30px 20px;
  flex-direction: row;
  align-items: center;
  border-radius: $border-radius;

  span {
    margin-right: 20px;
    color: $primary;
    font-size: $font-size-xl;
  }
}

.chart-wrap {
  .statistics-chart {
    height: 220px;
    padding: 20px 0;
    width: 100%;
  }

  .statistics-chart>div {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .chart-wrap {
    .statistics-chart {
      height: 260px;
    }
  }
}


@include media-breakpoint-up(lg) {
  .chart-wrap {
    .statistics-chart {
      height: 300px;
    }
  }
}
