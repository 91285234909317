// loading-challenge

.loading-challenge {
  background-color: $white;
  height: 90vh;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  .loading-title {
    color: $secondary;
    font-size: $title-sm;
    line-height: $title-line-height-sm;
    letter-spacing: $title-letter-spacing-sm;
    font-weight: $font-weight-normal;
    font-style: normal;
    text-align: center;
    margin-bottom:30px;
  }

  .loading-subtitle {
    color: $secondary;
    font-size: $font-size-lg;
    line-height: $line-height-sm;
    font-weight: $font-weight-normal;
    font-style: normal;
    text-align: center;
  }

  .loading {
    display: flex;
    width: 80%;
    height: 300px;
    margin: 0 auto;
    position: relative;

    .ball {
      border-radius: 50px;
    }

    .ball:nth-child(1) {
      position: absolute;
      left: 25%;
      top: 50%;
      margin-top: -13px;
      width: 26px;
      height: 26px;
      background: $secondary;
      animation: right 1s infinite ease-in-out;
    }

    .ball:nth-child(2) {
      position: absolute;
      left: 36%;
      top: 25%;
      margin-top: -26px;
      width: 52px;
      height: 52px;
      background: $primary;
      animation: left 1.1s infinite ease-in-out;
    }

    .ball:nth-child(3) {
      position: absolute;
      left: 46%;
      top: 50%;
      margin-top: -25px;
      width: 52px;
      height: 52px;
      background: #00D7FF;
      animation: right 1.05s infinite ease-in-out;
    }

    .ball:nth-child(4) {
      position: absolute;
      right: 38%;
      top: 25%;
      margin-top: -13px;
      width: 26px;
      height: 26px;
      background: $primary;
      animation: left 1.15s infinite ease-in-out;
    }

    .ball:nth-child(5) {
      position: absolute;
      right: 36%;
      top: 75%;
      margin-top: -13px;
      width: 26px;
      height: 26px;
      background: $secondary;
      animation: right 1.1s infinite ease-in-out;
    }

    .ball:nth-child(6) {
      position: absolute;
      right: 25%;
      top: 50%;
      margin-top: -13px;
      width: 26px;
      height: 26px;
      background: #00D7FF;
      animation: left 1.05s infinite ease-in-out;
    }
  }

  .loading:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    height: 1px;
    width: 100%;
    background: $primary;
    border-radius: 20px;
  }
}


@include media-breakpoint-up(lg) {
  .loading-challenge {
    .loading {
      width: 60%;
    }
  }
}


@-webkit-keyframes right {
  0% {
    -webkit-transform: translate(-15px);
  }

  50% {
    -webkit-transform: translate(15px);
  }

  100% {
    -webkit-transform: translate(-15px);
  }
}

@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(15px);
  }

  50% {
    -webkit-transform: translate(-15px);
  }

  100% {
    -webkit-transform: translate(15px);
  }
}

@-moz-keyframes right {
  0% {
    -moz-transform: translate(-15px);
  }

  50% {
    -moz-transform: translate(15px);
  }

  100% {
    -moz-transform: translate(-15px);
  }
}

@-moz-keyframes left {
  0% {
    -moz-transform: translate(15px);
  }

  50% {
    -moz-transform: translate(-15px);
  }

  100% {
    -moz-transform: translate(15px);
  }
}

@keyframes right {
  0% {
    transform: translate(-15px);
  }

  50% {
    transform: translate(15px);
  }

  100% {
    transform: translate(-15px);
  }
}

@keyframes left {
  0% {
    transform: translate(15px);
  }

  50% {
    transform: translate(-15px);
  }

  100% {
    transform: translate(15px);
  }
}
