// btn icon

.btn-icon {
  display: flex;
  justify-content: center;
  span {
    margin-right:10px;
  }
}

//btns

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: none;
}

.btn.stretched-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  // Just in case `pointer-events: none` is set on a parent
  pointer-events: auto;
  content: "";
  // IE10 bugfix, see https://stackoverflow.com/questions/16947967/ie10-hover-pseudo-class-doesnt-work-without-background-color
  background-color: rgba(0, 0, 0, 0);
}

.btn.stretched-link:hover {
  border: 1px solid $primary;
}

// more

.more {
  display: flex;
  align-items: middle;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-normal;
  font-style: normal;

  a.btn,
  button.btn {
    color: $primary;
    padding: 0;

    span {
      margin-left: 10px;
    }
  }
}

// btns-x-2 

.btns-x-2 {
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: column;

  a.btn,
  button.btn {
    margin-right: 0;
    width: 100%;
    padding: 18px 24px;
    margin-bottom: 10px;
  }

  .btn+.btn {
    margin-right: 0;
  }
}

@include media-breakpoint-up(lg) {
  .btns-x-2 {
    flex-direction: row;

    a.btn,
    button.btn {
      margin-right: 20px;
      width: calc(50% - 10px);
      margin-bottom: 0;
    }
  }
}

// btn-close-container

main {
  .btn-close-container {
    position: fixed;
    width:100%;
    height: 80px;
    top: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient($white 60%, rgba($white,0) 100%);

    .btn-close-challenger {
      position: absolute;
      top: 18px;
      right: 30px;
      font-size: $font-size-xl;
      color: $primary;
      padding: 0;
      margin: 0;
    }
  }
}
