// card-game

.games {
  .row [class*='col-'] {
    margin-bottom: 20px;
  }
}

.card.card-game {
  background-color: #ecf3fc;
  display: flex;
  border: none;
  padding: 20px;

  .badge {
    color: $secondary;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-normal;
    font-style: normal;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 10px;
  }

  .card-body {
    padding: 30px 0 0 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .player {
      display: flex;
      width: 38%;
      flex-direction: column;
      align-items: center;

      .profile-avatar {
        height: auto;
        margin: 0;
        margin: 0 0 5px 0;
      }

      .profile-name {
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
        font-weight: $font-weight-normal;
        font-style: normal;
      }

      .team {
        color: $secondary;
        font-size: $font-size-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
      }
    }

    .score {
      color: $secondary;
      font-size: $title-sm;
      line-height: $title-line-height-sm;
      letter-spacing: $title-letter-spacing-sm;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin: 5px 0 0 0;
      text-align: center;
    }

    .bt-play {
      width: 100%;
      padding: 18px 24px;
      font-size: $font-size-lg;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card.card-game {
    height: 100%;
  }
}
