// card-messages

.messages {
  select {
    margin-bottom: 40px;
    padding: 20px;
  }
}

.messages-list {
  .messages-list-header {
    background-color: #ecf3fc;
    display: flex;
    border: none;
    padding: 30px 20px;
    flex-direction: row;
    align-items: center;
    border-radius: $border-radius;
    margin-bottom: 30px;

    span {
      margin-right: 20px;
      background-color: $primary;
      color: $white;
      padding: 4px;
      border-radius: 100%;
      border: 4px double $white;
    }
  }

  .row [class*='col-'] {
    margin-bottom: 20px;
  }

  ol.custom-numbers {
    counter-reset: section;
    list-style-type: none;
    position: relative;
    padding: 0;
  }

  ol.custom-numbers>li {
    position: relative;
    margin-bottom: 20px;
  }

  ol.custom-numbers>li::before {
    counter-increment: section;
    content: counters(section, ".") " ";
    font-size: $title-xs;
    line-height: $title-line-height-xs;
    letter-spacing: $title-letter-spacing-xs;
    font-weight: $font-weight-normal;
    font-style: normal;
    color: $primary;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }
}

.card.card-message {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: star;
  border: none;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-200;
  border-radius: 0;

  .sender {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-bottom: 10px;

    .profile-avatar {
      margin-right: 10px;
    }

    .profile-name {
      padding-right: 22px;
    }
  }

  .message-body {
    color: $gray-500;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-normal;
    font-style: normal;
    margin-bottom: 10px;
    padding: 0;
  }

  .message-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;

    span {
      color: $gray-500;
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin-right: 22px;
      margin-bottom: 10px;
    }

    .btn {
      text-decoration: none;
      padding: 0;
      height: 20px;
      margin-bottom: 10px;
      margin-left: auto;
    }

    .btn:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: "";
    }
  }
}

.card.card-message.new-message {
  background-color: #FDF3F3;

  .badge {
    margin-right: 0;
    margin-left: 5px;
    font-size: 0.75rem;
    line-height: 0.938;
    background-color: #fff;
    color: #E00F0F;
    padding: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 24px;
    right: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .card.card-message {
    height: 100%;
    flex-direction: row;
    align-items: center;

    .sender {
      margin-bottom: 0;

      .profile-name {
        padding-right: 0;
      }
    }

    .message-body {
      margin-bottom: 0;
      flex-wrap: nowrap;
      padding: 0 15px;
    }

    .message-details {
      flex-wrap: nowrap;

      span {
        margin-bottom: 0;
      }

      .btn {
        margin-bottom: 0;
      }
    }
  }

  .card.card-message.new-message {
    .badge {
      position: relative;
      top: auto;
      right: auto;
    }
  }
}
