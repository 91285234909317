// ranking

.ranking {
  background-color: #ecf3fc;
  border-radius: $border-radius;
  position: relative;
  padding: 68px 0 0 0;
  margin-bottom: 40px;
  max-width: 100%;

  .row [class*='col-'] {
    padding-right: 20px;
    padding-left: 20px;
  }

  .form-switch {
    position: absolute;
    top: 20px;
    left: 20px;

    .form-check-input {
      margin-right: 10px;
    }
  }

  .podium {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    background: none;
    background-size: contain;
    min-height: 270px;
    max-width: 420px;

    .position {
      text-align: center;

      .position-number {
        color: $secondary;
        font-size: $title-xxs;
        line-height: $title-line-height-xxs;
        letter-spacing: $title-letter-spacing-xxs;
        font-weight: $font-weight-normal;
        font-style: normal;
        margin-bottom: 10px;
      }

      .img-profile {
        width: 100%;
        height: auto;
      }

      .name {
        color: $secondary;
        font-size: $title-xxs;
        line-height: $title-line-height-xxs;
        font-weight: $font-weight-normal;
        font-style: normal;
      }

      .team {
        color: $secondary;
        font-size: $font-size-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
      }

      .points {
        color: $gray-500;
        font-size: $font-size-base;
        line-height: $line-height-base;
        font-weight: $font-weight-normal;
        font-style: normal;
      }
    }

    .position.first-p {
      width: 34%;
      padding-top: 30px;

      .img-profile {
        max-width: 100px;
      }

      .position-number {
        color: $primary;
        font-size: $title-sm;
        line-height: $title-line-height-sm;
        letter-spacing: $title-letter-spacing-sm;
        font-weight: $font-weight-normal;
        font-style: normal;
      }

      .name {
        color: $secondary;
        font-size: $title-sm;
        line-height: $title-line-height-sm;
        letter-spacing: $title-letter-spacing-sm;
        font-weight: $font-weight-normal;
        font-style: normal;
      }
    }

    .position.second-p {
      width: 33%;
      padding-top: 80px;

      .img-profile {
        max-width: 70px;
      }
    }

    .position.third-p {
      width: 33%;
      padding-top: 100px;

      .img-profile {
        max-width: 50px;
      }
    }
  }

  .top-ten {
    display: flex;
    flex-flow: wrap;
    padding: 20px 0;

    .position-number {
      color: $secondary;
      font-size: $title-xxs;
      line-height: $title-line-height-xxs;
      letter-spacing: $title-letter-spacing-xxs;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin-right: 10px;
      width: 10px;
    }

    .name {
      color: $secondary;
      font-size: $title-xxs;
      line-height: $title-line-height-xxs;
      font-weight: $font-weight-normal;
      font-style: normal;
      width: calc(100% - 40px);
    }

    .team {
      color: $gray-500;
      font-size: $font-size-xs;
      font-weight: $font-weight-normal;
      font-style: normal;
      width: 100%;
      margin-left: 20px;
    }

    .points {
      color: $gray-500;
      font-size: $font-size-lg;
      line-height: $line-height-lg;
      font-weight: $font-weight-normal;
      font-style: normal;
      width: 100%;
      margin-left: 20px;
    }
  }

  .item-container {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@include media-breakpoint-up(xl) {
  .ranking {

    .container {
      padding-right: 20px;
      padding-left: 20px;
    }

    .row [class*='col-'] {
      padding: 0;
    }

    .podium {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 60px 0 30px;
      background: rgba($white, 0) url("../../../images/utils/podium-bg.png") no-repeat scroll bottom center;
      background-size: contain;
      min-height: 300px;
      max-width: 420px;

      .position.first-p {
        padding-top: 50px;
        width: 40%;
      }

      .position.second-p {
        padding-top: 110px;
        width: 35%;
      }

      .position.third-p {
        padding-top: 130px;
        width: 25%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .ranking {
    .item-container {
      flex-direction: row;
    }
  }
}
