// alerts

.swal2-container {
  .swal2-title {
    padding: 30px 20px;

    .swal2-html-container {
      font-size: $title-xs;
      line-height: $title-line-height-xs;
      letter-spacing: $title-letter-spacing-xs;
      font-weight: $font-weight-normal;
      font-style: normal;
    }

    .swal2-actions {
      width: 100%;

      button.swal2-styled {
        width: 100%;
        margin: 0;
        padding: 16px 18px;
        font-size: $font-size-base;
        line-height: $line-height-base;
        font-weight: $font-weight-normal;
        font-style: normal;
      }
    }
  }
}


@include media-breakpoint-up(xl) {
  .swal2-container {
    .swal2-title {
      padding: 40px 80px;

      .swal2-html-container {
        font-size: $title-md;
        line-height: $title-line-height-md;
        letter-spacing: $title-letter-spacing-md;
      }
    }
  }
}
