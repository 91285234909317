// counter

.base-timer {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 20px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 125% */
  letter-spacing: -0.32px;
}



@include media-breakpoint-up(lg) {
  .base-timer {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 20px;
  }

  .base-timer__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 125% */
    letter-spacing: -0.32px;
  }
}
