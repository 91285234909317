// radiobutton-group

.radiobutton-group.theme {
  .form__options {
    .form__answer {
      label {
        box-shadow: $box-shadow-sm;
        display: block;
        width: 100%;
        cursor: pointer;
        transition: all .15s ease-in-out;
        border: 1px solid $white;
        border-radius: $border-radius;
        background-color: $white;
        position: relative;
        padding: 20px;
        margin-bottom: 20px;

        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 26px;
          height: 26px;
          background-color: $gray-500;
          border-radius: 50%;
          top: -13px;
          left: -13px;
          opacity: 0;
          transform: scale(0);
          transition: 0.25s ease;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
          background-size: 12px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }

        &:hover {
          border-color: $gray-500;

          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }

        &:hover,
        &:focus,
        &:active {}
      }

      /* Input style */

      input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
      }

      input[type="radio"]:active~label {
        opacity: 1;
      }

      input[type="radio"]:checked~label {
        opacity: 1;
        border: 1px solid $primary;


        &:before {
          transform: scale(1);
          opacity: 1;
          background-color: $primary;
        }
      }
    }
  }
}



.radiobutton-group.opponent {
  .form__options {
    .form__answer {
      label {
        display: flex;
        width: 100%;
        cursor: pointer;
        transition: all .15s ease-in-out;
        border-radius: $border-radius;
        background-color: $white;
        position: relative;
        padding: 10px 20px;
        border: 1px solid $white;

        .profile-avatar {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        &:hover {
          border: 1px solid $white;
          background-color: #ecf3fc;

          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }

        &:hover,
        &:focus,
        &:active {}

        .name {
          color: $secondary;
          font-size: $title-xxs;
          line-height: $title-line-height-xxs;
          font-weight: $font-weight-normal;
          font-style: normal;
        }

        .team {
          color: $secondary;
          font-size: $font-size-xs;
          font-weight: $font-weight-normal;
          font-style: normal;
          position: absolute;
          bottom: 6px;
          left: 70px;
        }
      }

      /* Input style */

      input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
      }

      input[type="radio"]:active~label {
        opacity: 1;
      }

      input[type="radio"]:checked~label {
        opacity: 1;
        background-color: #ecf3fc;
        border: 1px solid $white;


        &:before {
          transform: scale(1);
          opacity: 1;
          background-color: $primary;
        }
      }
    }
  }
}
