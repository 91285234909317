// card-user



.card.card-user {
  background-color: $white;
  display: flex;
  border: none;
  padding: 0;
  box-shadow: none;
  flex-direction: row;
  align-items: center;
  margin-bottom:30px;

  .profile-avatar {
    margin-right: 10px;
  }
}

