// card-historical

.historicals {
  .row [class*='col-'] {
    margin-bottom: 20px;
  }
}

.card.card-historical.historical-won {
  background-color: #F4F8F6;
}

.card.card-historical.historical-lost {
  background-color: #FDF3F3;
}

.card.card-historical.historical-tie {
  background-color: #FCF5E5;
}

.swiper-historical.self, .swiper-historical.battles {
  .card.card-historical {
    .card-body {
      justify-content: center;
    }
  }
}

.card.card-historical {
  background-color: #ecf3fc;
  display: flex;
  border: none;
  padding: 40px 20px 20px 20px;

  .badge {
    color: $secondary;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-normal;
    font-style: normal;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 10px;
  }

  .badge-date {
    color: $secondary;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-weight-normal;
    font-style: normal;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    padding: 8px 10px;
  }

  .card-body {
    padding: 30px 0 0 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .player {
      display: flex;
      width: 38%;
      flex-direction: column;
      align-items: center;

      .profile-avatar {
        height: auto;
        margin: 0 0 5px 0;
      }

      .profile-name {
        margin: 0;
        width: 100%;
        text-align: center;
      }

      .player-points {
        color: $secondary;
        font-size: $title-xs;
        line-height: $title-line-height-xs;
        letter-spacing: $title-letter-spacing-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
      }
    }

    .score {
      color: $secondary;
      font-size: $title-sm;
      line-height: $title-line-height-sm;
      letter-spacing: $title-letter-spacing-sm;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin: 5px 0 0 0;
      text-align: center;
    }

    .bt-play {
      width: 100%;
      padding: 18px 24px;
      font-size: $font-size-lg;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card.card-historical {
    height: 100%;

    .card-body {
      .player {
        .player-points {
          font-size: $title-md;
          line-height: $title-line-height-md;
          letter-spacing: $title-letter-spacing-md;
        }
      }
    }
  }
}
