// historical

.historical {
  position: relative;
}

.historical-header {
  background-color: #ecf3fc;
  display: flex;
  border: none;
  padding: 30px 20px;
  flex-direction: row;
  align-items: center;
  border-radius: $border-radius;
  margin-bottom: 20px;

  span {
    margin-right: 20px;
    color: $primary;
    font-size: $font-size-xl;
  }
}
