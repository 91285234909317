// info-data


.info-data {
  margin-left: auto;
  max-width: 720px;

  .info-title {
    color: $secondary;
    font-size: $title-sm;
    line-height: $title-line-height-sm;
    letter-spacing: $title-letter-spacing-sm;
    font-weight: $font-weight-normal;
    font-style: normal;
    margin-bottom: 20px;
  }

  .info-text {
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-normal;
    font-style: normal;
  }

  .info-link {
    margin-bottom: 40px;
    a {
      padding: 0;
    }
  }
}


@include media-breakpoint-up(lg) {
  .info-data {
    margin-left: 190px;

    .info-title {
      font-size: $title-md;
      line-height: $title-line-height-md;
      letter-spacing: $title-letter-spacing-md;
      font-weight: $font-weight-normal;
      font-style: normal;
    }
  }
}
