// -----------------------------------------------------------------------------
// Basic styles
// -----------------------------------------------------------------------------


// STICY FOOTER 

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  background: rgba($secondary, 1);
  color: $secondary;
  font-family: 'Graphik Web', Tahoma, sans-serif;
}

body.challenge {
  background: rgba($white, 1);
}

body.resumen {
  background: rgba($white, 1);
  padding-bottom: 100px;
}

main {}

footer {}

a.btn-link,
button.btn-link,
a.btn-link:hover,
button.btn-link:hover,
a,
button,
a:hover,
button:hover {
  text-decoration: none;
}

b,
strong {
  font-weight: normal !important;
}


// SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba($white, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($primary, 0.25);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

@include media-breakpoint-up(md) {

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba($dark, 0.25);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba($dark, 0.5);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  }
}
