// countown-chadllenge

.loading-challenge {
  .players {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 30px;
    justify-content: center;

    .player {
      display: flex;
      position: relative;

      .img-profile {
        width: 50px;
        height: auto;
        margin: 20px 0;
      }
    }

    .player:first-child:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 100%;
      height: 1px;
      width: 80%;
      background: $primary;
      border-radius: 20px;
    }

    .player:last-child:before {
      position: absolute;
      content: "";
      top: 50%;
      right: 100%;
      height: 1px;
      width: 80%;
      background: $primary;
      border-radius: 20px;
    }

    .score {
      color: $secondary;
      font-size: $title-xxs;
      line-height: $title-line-height-xxs;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin: 0 14px;

      .player-winer {
        margin: 0;
        color: $success;
      }

      span {
        margin: 0 20px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .loading-challenge {
    .players {
      .player {
        justify-content: space-between;

        .img-profile {
          width: 100px;
        }
      }
    }
  }
}


// countown-chadllenge
.countown-chadllenge {
  margin: 0;
  display: flex;
  align-items: center;
  width: 160px;
  height: 120px;
  justify-content: center;

  .circle-bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
  }

  .circle-cd {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .text {
      color: $white;
      font-size: $title-lg;
      opacity: 0;
      position: absolute;
    }
  }
}

.countown-chadllenge.animated {

  .circle-bg {
    animation: color-change 3s ease;
  }

  .circle-cd {

    .text {
      animation: countdown 1s linear;
    }

    .text:nth-child(2) {
      animation-delay: 1s;
    }

    .text:nth-child(3) {
      animation-delay: 2s;
    }

    .text:nth-child(4) {
      animation-delay: 3s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes countdown {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes color-change {

  0%,
  25% {
    background-color: $primary;
    transform: rotate(0deg);
    width: 7rem;
    height: 7rem;
  }

  50% {
    background-color: $secondary;
    transform: rotate(180deg);
    width: 6rem;
    height: 6rem;
  }

  100% {
    background-color: #00D7FF;
    transform: rotate(360deg);
    width: 4rem;
    height: 4rem;
  }
}


@include media-breakpoint-up(lg) {
  .countown-chadllenge {
    width: 300px;
    height: 300px;

    .circle-cd {
      .text {
        color: $white;
        font-size: $title-xxl;
      }
    }
  }

  @keyframes countdown {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes color-change {

    0%,
    25% {
      background-color: $primary;
      transform: rotate(0deg);
      width: 11rem;
      height: 11rem;
    }

    50% {
      background-color: $secondary;
      transform: rotate(180deg);
      width: 9rem;
      height: 9rem;
    }

    100% {
      background-color: #00D7FF;
      transform: rotate(360deg);
      width: 7rem;
      height: 7rem;
    }
  }
}
