// challenge

.challenge {
  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .question {
      .question-theme {
        font-size: $title-xxs;
        line-height: $title-line-height-xxs;
        font-weight: $font-weight-normal;
        font-style: normal;
        color: $primary;
        margin-bottom: 10px;
      }

      .question-tx {
        font-size: $title-xs;
        line-height: $title-line-height-xs;
        letter-spacing: $title-letter-spacing-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
        color: $secondary;
        margin-bottom: 20px;
      }

      .question-img {
        margin-bottom: 20px;
        border-radius: $border-radius-lg;
      }
    }

    .answer {

      .form__answer label::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
      }

      .form__answer input[type="radio"]:checked~label::before {
        transform: scale(0);
        opacity: 0;
        background-color: transparent;
      }

      .form__answer label:hover {
        border-color: $primary;
      }

      .form__answer label:hover::before {
        transform: scale(0);
        opacity: 0;
      }

      /*answer-right*/

      .form__answer.answer-right {
        label {
          border: 1px solid $success;
          background-color: rgba($success, 0.1)
        }

        input {
          pointer-events: none;
        }
      }

      .form__answer.answer-right input[type="radio"]:checked~label {
        border: 1px solid $success;
      }

      /*answer-wrong*/

      .form__answer.answer-wrong {
        label {
          border: 1px solid $danger;
          background-color: rgba($danger, 0.1)
        }

        input {
          pointer-events: none;
        }
      }

      .form__answer.answer-wrong input[type="radio"]:checked~label {
        border: 1px solid $danger;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .challenge {
    .container {
      max-width: 620px;

      .question {
        .question-theme {
          color: $primary;
          font-size: $title-xs;
          line-height: $title-line-height-xs;
          letter-spacing: $title-letter-spacing-xs;
          font-weight: $font-weight-normal;
          font-style: normal;
          margin-bottom: 10px;
        }

        .question-tx {
          color: $secondary;
          font-size: $title-md;
          line-height: $title-line-height-md;
          letter-spacing: $title-letter-spacing-md;
          font-weight: $font-weight-normal;
          font-style: normal;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.stopwatch {
  margin: 30px 0 10px 0;
  color: $secondary;
  font-size: $title-md;
  line-height: $title-line-height-md;
  letter-spacing: $title-letter-spacing-md;
  font-weight: $font-weight-normal;
  font-style: normal;

  input,
  input:active,
  input:focus {
    color: $secondary;
    border: none;
    font-size: $title-md;
    line-height: $title-line-height-md;
    letter-spacing: $title-letter-spacing-md;
    font-weight: $font-weight-normal;
    font-style: normal;
  }
}

@include media-breakpoint-up(lg) {
  .stopwatch {
    margin: 60px 0 20px 0;
    color: $secondary;
    font-size: $title-md;
    line-height: $title-line-height-md;
    letter-spacing: $title-letter-spacing-md;
    font-weight: $font-weight-normal;
    font-style: normal;
  }
}
