// btn-indicator 

.btn-indicator {
  padding: 40px 0;
}

.btn-indicator[aria-expanded="false"]::after {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e91d";
  position: relative;
  font-size: 1.25rem;
  color: $primary;
  margin-left: 10px;top: 3px;
}

.btn-indicator[aria-expanded="true"]::after {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  content: "\e920";
  position: relative;
  font-size: 1.25rem;
  color: $primary;
  z-index: 1;
  margin-left: 10px;top: 3px;
}
