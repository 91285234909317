// forms

.form-login {

  .was-validated .form-control:valid,
  .form-control.is-valid {
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

// focus
.form-check-input:focus {
  border-color: #000;
  outline: 1;
  box-shadow: none;
}

/*active*/

input[type="color"]:active,
input[type="color"]:focus,
input[type="date"]:active,
input[type="date"]:focus,
input[type="datetime"]:active,
input[type="datetime"]:focus,
input[type="datetime-local"]:active,
input[type="datetime-local"]:focus,
input[type="email"]:active,
input[type="email"]:focus,
input[type="month"]:active,
input[type="month"]:focus,
input[type="number"]:active,
input[type="number"]:focus,
input[type="password"]:active,
input[type="password"]:focus,
input[type="search"]:active,
input[type="search"]:focus,
input[type="tel"]:active,
input[type="tel"]:focus,
input[type="text"]:active,
input[type="text"]:focus,
input[type="time"]:active,
input[type="time"]:focus,
input[type="url"]:active,
input[type="url"]:focus,
input[type="week"]:active,
input[type="week"]:focus,
input:not([type]):active,
input:not([type]):focus,
textarea:active,
textarea:focus {
  transition: border-color 0.2s;
  border-color: $primary;
  border-style: solid;
  border-width: 2px;
  outline: none;
  box-shadow: none;
  padding: 19px 19px;
/*  padding: 14px 18px 17px;*/
}



/*form-control*/

.form-floating>label {
  color: $gray-400;
}

.form-control:focus,
.form-control:active {
  label {
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
    border-color: #000;
    border-style: solid;
    border-width: 2px;
    outline: none;
  }
}

.form-control:focus,
.form-control:active {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #90baec;
  outline: 0;
  box-shadow: none;
  padding: 16px 11px;
  border: 2px solid $primary;
}

// search-form

.search-form {
  position: relative;

  input.form-control {
    padding: 20px 20px 20px 40px;
  }

  input.form-control:focus,
  input.form-control:active {
    padding: 19px 19px 19px 39px;
  }

  .btn-lens {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

// Swicher

.form-switch {
  .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
    width: 50px;
    height: 30px;
    background-color: rgba(15, 25, 65, 0.10);
    border: none;
    margin-top: 0;
    margin-right: 0.25rem;
  }

  .form-check-input:checked {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
    background-color: $primary;
  }

  .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
  }
}

.form-label {
  margin-bottom: 20px;
  color: $gray-500;
}
