// card-ranking

.rankings {
  select {
    margin-bottom: 40px;
    padding: 20px;
  }
}

.ranking-list {

  .ranking-list-header {
    background-color: #ecf3fc;
    display: flex;
    border: none;
    padding: 30px 20px;
    flex-direction: row;
    align-items: center;
    border-radius: $border-radius;
    margin-bottom: 30px;

    span {
      margin-right: 20px;
      background-color: $primary;
      color: $white;
      padding: 4px;
      border-radius: 100%;
      border: 4px double $white;
    }
  }

  .row [class*='col-'] {
    margin-bottom: 20px;
  }

  ol.custom-numbers {
    counter-reset: section;
    list-style-type: none;
    position: relative;
    padding: 0;
  }

  ol.custom-numbers>li {
    position: relative;
    margin-bottom: 20px;
  }

  ol.custom-numbers>li::before {
    counter-increment: section;
    content: counters(section, ".") " ";
    font-size: $title-xs;
    line-height: $title-line-height-xs;
    letter-spacing: $title-letter-spacing-xs;
    font-weight: $font-weight-normal;
    font-style: normal;
    color: $primary;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
  }
}

.card.card-ranking {
  background-color: $white;
  display: flex;
  border: 1px solid $white;
  padding: 15px 20px 15px 50px;
  box-shadow: $box-shadow-sm;
  flex-direction: row;

  .profile-avatar {
    margin-right: 10px;
  }

  .team {
    color: $secondary;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    font-style: normal;
    position: absolute;
    bottom: 14px;
    left: 100px;
  }

  .points {
    margin-left: auto;
  }
}

.card.card-ranking.user-active {
  border: 1px solid $primary;
}


.card.card-ranking.user-notop {
  .position {
    font-size: $title-xs;
    line-height: $title-line-height-xs;
    letter-spacing: $title-letter-spacing-xs;
    font-weight: $font-weight-normal;
    font-style: normal;
    color: $primary;
    width: 100%;
    text-align: right;
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
    width: 42px;
  }
}
