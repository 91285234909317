// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

// @font-face declarations


@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?gp2jom');
  src:  url('../../fonts/icomoon.eot?gp2jom#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?gp2jom') format('truetype'),
    url('../../fonts/icomoon.woff?gp2jom') format('woff'),
    url('../../fonts/icomoon.svg?gp2jom#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-circle:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-add-circle:before {
  content: "\e902";
}
.icon-address-book:before {
  content: "\e903";
}
.icon-archive:before {
  content: "\e904";
}
.icon-arrow-down:before {
  content: "\e905";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
.icon-arrow-up:before {
  content: "\e908";
}
.icon-attachment:before {
  content: "\e909";
}
.icon-badge:before {
  content: "\e90a";
}
.icon-bell:before {
  content: "\e90b";
}
.icon-bell-strikethrough:before {
  content: "\e90c";
}
.icon-briefcase:before {
  content: "\e90d";
}
.icon-briefcase-check:before {
  content: "\e90e";
}
.icon-briefcase-strikethrough:before {
  content: "\e90f";
}
.icon-building:before {
  content: "\e910";
}
.icon-bullet-list:before {
  content: "\e911";
}
.icon-calculator:before {
  content: "\e912";
}
.icon-calendar:before {
  content: "\e913";
}
.icon-calendar-check:before {
  content: "\e914";
}
.icon-calendar-export:before {
  content: "\e915";
}
.icon-calendar-today:before {
  content: "\e916";
}
.icon-calendar-unavailable:before {
  content: "\e917";
}
.icon-calendar-warning:before {
  content: "\e918";
}
.icon-camera:before {
  content: "\e919";
}
.icon-career:before {
  content: "\e91a";
}
.icon-chat:before {
  content: "\e91b";
}
.icon-check:before {
  content: "\e91c";
}
.icon-chevron-down:before {
  content: "\e91d";
}
.icon-chevron-left:before {
  content: "\e91e";
}
.icon-chevron-right:before {
  content: "\e91f";
}
.icon-chevron-up:before {
  content: "\e920";
}
.icon-clock:before {
  content: "\e921";
}
.icon-close:before {
  content: "\e922";
}
.icon-cookie:before {
  content: "\e923";
}
.icon-copy:before {
  content: "\e924";
}
.icon-current-location:before {
  content: "\e925";
}
.icon-dashboard:before {
  content: "\e926";
}
.icon-database:before {
  content: "\e927";
}
.icon-database-import:before {
  content: "\e928";
}
.icon-disabled:before {
  content: "\e929";
}
.icon-document:before {
  content: "\e92a";
}
.icon-document-check:before {
  content: "\e92b";
}
.icon-document-clock:before {
  content: "\e92c";
}
.icon-document-lock:before {
  content: "\e92d";
}
.icon-document-pdf:before {
  content: "\e92e";
}
.icon-document-search:before {
  content: "\e92f";
}
.icon-dollar:before {
  content: "\e930";
}
.icon-download:before {
  content: "\e931";
}
.icon-edit:before {
  content: "\e932";
}
.icon-education:before {
  content: "\e933";
}
.icon-email:before {
  content: "\e934";
}
.icon-email-open:before {
  content: "\e935";
}
.icon-euro:before {
  content: "\e936";
}
.icon-external:before {
  content: "\e937";
}
.icon-eye:before {
  content: "\e938";
}
.icon-eye-strikethrough:before {
  content: "\e939";
}
.icon-face-angry:before {
  content: "\e93a";
}
.icon-facebook-filled:before {
  content: "\e93b";
}
.icon-face-happy:before {
  content: "\e93c";
}
.icon-face-neutral:before {
  content: "\e93d";
}
.icon-face-overjoyed:before {
  content: "\e93e";
}
.icon-face-sad:before {
  content: "\e93f";
}
.icon-factory:before {
  content: "\e940";
}
.icon-fax:before {
  content: "\e941";
}
.icon-feedback:before {
  content: "\e942";
}
.icon-filter:before {
  content: "\e943";
}
.icon-fingerprint:before {
  content: "\e944";
}
.icon-flag:before {
  content: "\e945";
}
.icon-folder:before {
  content: "\e946";
}
.icon-globe:before {
  content: "\e947";
}
.icon-google-filled:before {
  content: "\e948";
}
.icon-gps:before {
  content: "\e949";
}
.icon-gps-filled:before {
  content: "\e94a";
}
.icon-headset:before {
  content: "\e94b";
}
.icon-heart:before {
  content: "\e94c";
}
.icon-heart-filled:before {
  content: "\e94d";
}
.icon-help:before {
  content: "\e94e";
}
.icon-history:before {
  content: "\e94f";
}
.icon-home:before {
  content: "\e950";
}
.icon-hourglass:before {
  content: "\e951";
}
.icon-image:before {
  content: "\e952";
}
.icon-info:before {
  content: "\e953";
}
.icon-instagram-filled:before {
  content: "\e954";
}
.icon-label:before {
  content: "\e955";
}
.icon-laptop:before {
  content: "\e956";
}
.icon-link:before {
  content: "\e957";
}
.icon-linkedin-filled:before {
  content: "\e958";
}
.icon-lock:before {
  content: "\e959";
}
.icon-login:before {
  content: "\e95a";
}
.icon-logout:before {
  content: "\e95b";
}
.icon-magnet:before {
  content: "\e95c";
}
.icon-marker:before {
  content: "\e95d";
}
.icon-maternity:before {
  content: "\e95e";
}
.icon-medical:before {
  content: "\e95f";
}
.icon-megaphone:before {
  content: "\e960";
}
.icon-menu:before {
  content: "\e961";
}
.icon-microphone:before {
  content: "\e962";
}
.icon-minus:before {
  content: "\e963";
}
.icon-more:before {
  content: "\e964";
}
.icon-multi-layer:before {
  content: "\e965";
}
.icon-pen-nib:before {
  content: "\e966";
}
.icon-person:before {
  content: "\e967";
}
.icon-person-check:before {
  content: "\e968";
}
.icon-person-warning:before {
  content: "\e969";
}
.icon-phone:before {
  content: "\e96a";
}
.icon-phone-mobile:before {
  content: "\e96b";
}
.icon-pin:before {
  content: "\e96c";
}
.icon-pin-filled:before {
  content: "\e96d";
}
.icon-play-filled:before {
  content: "\e96e";
}
.icon-podcast:before {
  content: "\e96f";
}
.icon-pound:before {
  content: "\e970";
}
.icon-print:before {
  content: "\e971";
}
.icon-promotion:before {
  content: "\e972";
}
.icon-puzzle:before {
  content: "\e973";
}
.icon-redo:before {
  content: "\e974";
}
.icon-refresh:before {
  content: "\e975";
}
.icon-reply:before {
  content: "\e976";
}
.icon-robot:before {
  content: "\e977";
}
.icon-salary:before {
  content: "\e978";
}
.icon-search:before {
  content: "\e979";
}
.icon-settings:before {
  content: "\e97a";
}
.icon-share:before {
  content: "\e97b";
}
.icon-shield:before {
  content: "\e97c";
}
.icon-star:before {
  content: "\e97d";
}
.icon-star-filled:before {
  content: "\e97e";
}
.icon-star-half-filled:before {
  content: "\e97f";
}
.icon-task-check:before {
  content: "\e980";
}
.icon-thumb-down:before {
  content: "\e981";
}
.icon-thumb-up:before {
  content: "\e982";
}
.icon-tiktok-filled:before {
  content: "\e983";
}
.icon-tools:before {
  content: "\e984";
}
.icon-trash:before {
  content: "\e985";
}
.icon-twitter-filled:before {
  content: "\e986";
}
.icon-undo:before {
  content: "\e987";
}
.icon-upload:before {
  content: "\e988";
}
.icon-upload-success:before {
  content: "\e989";
}
.icon-vacation:before {
  content: "\e98a";
}
.icon-view-column:before {
  content: "\e98b";
}
.icon-view-grid:before {
  content: "\e98c";
}
.icon-view-highlight:before {
  content: "\e98d";
}
.icon-view-list:before {
  content: "\e98e";
}
.icon-warning:before {
  content: "\e98f";
}
.icon-wechat-filled:before {
  content: "\e990";
}
.icon-weibo-filled:before {
  content: "\e991";
}
.icon-whatsapp:before {
  content: "\e992";
}
.icon-whatsapp-filled:before {
  content: "\e993";
}
.icon-xing-filled:before {
  content: "\e994";
}
.icon-yen:before {
  content: "\e995";
}
.icon-youtube-filled:before {
  content: "\e996";
}
