// main-nav

.main-nav {
  width: 100%;
  padding: 0;
  position: absolute;
  left: auto;
  top: auto;
  z-index: 100;
  /*  background: rgba($secondary, 1) url("../../../images/utils/login-bg.png") no-repeat scroll -200px bottom;*/

  .navbar-collapse {
    position: absolute;
    width: 100%;
    top: 0;
    background-color: $secondary;

    .brand-nav {
      display: block;
      margin: 16px 20px;
      width: 120px;
      height: 24px;
    }

    .brand-nav:after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      top: 63px;
      background-color: #6F758C;
      left: 20px;
    }

    .img-profile {
      margin-left: calc(100% - 60px);
      margin-top: 50px;
      margin-right: 30px;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      display: block;
    }
  }

  .navbar-collapse.show {
    position: fixed;
  }

  .navbar-nav {
    height: calc(100vh - 70px);
    flex-direction: column;
    padding: 30px 0 30px 30px;

    .nav-item {
      .nav-link {
        color: $gray-500;

        span {
          margin-right: 1rem;
          font-size: $font-size-base;
        }

        span.badge {
          margin-right: 0;
          margin-left: 5px;
          font-size: $font-size-xs;
          line-height: $line-height-xs;
          background-color: $white;
          color: $danger;
          padding: 0;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          justify-content: center;
          display: inline-flex;
          align-items: center;
        }
      }

      .nav-link:hover {
        color: $white;
      }

      .nav-link.active {
        color: $primary-light;
      }
    }
  }
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: none;
}

@include media-breakpoint-up(lg) {
  .main-nav {
    padding: 20px 10px 20px 20px;
    width: 230px;
    position: absolute;
    left: 0;
    top: 100px;

    .navbar-collapse {
      position: relative;
      top: auto;
      background-color: transparent;

      .brand-nav {
        display: none;
      }

      .img-profile {
        display: none;
      }
    }

    .navbar .nav-item .dropdown-menu {
      display: none;
    }

    .navbar .nav-item:hover .nav-link {
      color: $white;
    }

    .navbar .nav-item:hover .dropdown-menu {
      display: block;
    }

    .navbar .nav-item .dropdown-menu {
      margin-top: 0;
    }
  }
}
