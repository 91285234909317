.selector-form {
  margin-bottom: 10px;

  .row [class*="col-"] {
    margin-bottom: 20px;
  }
}

select.form-select {
  --bs-form-select-bg-img: url(../../../images/chevron-down.png);
  background-image: url(../../../images/chevron-down.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
  appearance: none;
  background-size: 20px 20px;
}

select::-ms-expand {
  display: none;
  /*Evita que se muestre la flecha por defecto en versiones de IE*/
}

option {
  font-weight: normal;
  display: block;
  padding-block-start: 0px;
  padding-block-end: 1px;
  min-block-size: 1.2em;
  padding-inline: 2px;
  white-space: nowrap;
}

select.form-select {
  padding-top: 15px;
  padding-right: 50px;
  padding-bottom: 18px;
  padding-left: 19px;


  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
  border: solid 1px #d7d7d7;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: none;
  color: #0f1941;
  cursor: initial;
  margin: 0;
  min-height: 60px;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select.form-select :active,
select.form-select :focus {
  transition: border-color 0.2s;
  border-color: #2175D9;
  border-style: solid;
  border-width: 2px;
  outline: none;
  box-shadow: none;
}

@include media-breakpoint-up(lg) {
  select.form-select {

    padding-top: 13px;
    padding-right: 50px;
    padding-bottom: 15px;
    padding-left: 19px;

    font-size: 18px;
    line-height: 30px;
  }
}

select.form-select:active,
select.form-select:focus {
  border-color: $primary;
  outline: 0;
  box-shadow: none;
  border-width: 2px;

  padding-top: 14px;
  padding-right: 18px;
  padding-bottom: 17px;
  padding-left: 18px;

  background-position: right 19px center;

}

@include media-breakpoint-up(lg) {

  select.form-select:active,
  select.form-select:focus {

    padding-top: 12px;
    padding-right: 18px;
    padding-bottom: 14px;
    padding-left: 18px;
  }
}
