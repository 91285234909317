// battle-selector

.battle-selector {
  display: flex;
  justify-content: end;
  padding: 20px;
  flex-direction: column;
  margin-bottom: 0;

  .battle-selector-title {
    font-size: $title-xxs;
    line-height: $title-line-height-xxs;
    letter-spacing: $title-letter-spacing-xxs;
    font-weight: $font-weight-normal;
    font-style: normal;
    color: $white;
    align-self: auto;
    margin-right: 25px;
    margin-bottom: 20px;
  }

  .profile-avatar {
    margin-left: 40px;
    flex-shrink: 0;
    align-self: center;
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .battle-selector {
    flex-direction: row;

    .battle-selector-title {
      align-self: end;
      margin-bottom: 0;
    }

    .profile-avatar {
      display: block;
    }
  }
}
