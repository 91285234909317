// main

main {
  background-color: $white;
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

@include media-breakpoint-up(lg) {
  main {
    margin-left: 0;
  }
}

main.main-ini {
  background-color: $white;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
}

@include media-breakpoint-up(lg) {
  main.main-ini {
    margin-left: 220px;
  }
}



.item-icon {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}
