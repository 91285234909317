// table-alumnos

.table-messages {
  font-size: $font-size-sm;

  table {
    width: 100%;
    margin: 0;
    background: $white;
    font-size: $font-size-sm;

    thead {
      tr {
        th {
          background: #ecf3fc;
          color: $primary;
          font-size: $font-size-base;
          line-height: $line-height-base;
          font-weight: $font-weight-normal;
          font-style: normal;
        }
      }
    }

    tbody {
      tr {
        td {
          color: $gray-500;
          font-size: $font-size-base;
          line-height: $line-height-base;
          font-weight: $font-weight-normal;
          font-style: normal;
        }

        td.td-mensaje {
          span {
            display: block;
            width: 300px;
          }
        }
      }
    }
  }

  .fixed-table-pagination {
    font-size: small;
    text-transform: uppercase;
  }

  .pagination-detail {

    .page-list {
      .btn-group {
        .btn {
          background: $white;
          border: none;
        }

        .btn:hover,
        .btn:focus {
          background: $white;
          border: none;
          color: $dark, ;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {}
