// swiper-meta

%transition_all_03s {
  transition: all .3s ease;
}

%backface_visibility_hidden {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.swiper-container {
  // display:inline-block;
  // vertical-align:top;
  float: left;
  transition: opacity .6s ease, transform .3s ease;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  .swiper-button-prev,
  .swiper-button-next {
    color: $white;
  }

  &.nav-slider {
    width: 20%;
    padding-left: 5px;

    .swiper-slide {
      cursor: pointer;
      opacity: .4;
      transition: opacity .3s ease;

      &.swiper-slide-active {
        opacity: 1;
      }

      .content {
        width: 100%;

        .title {
          font-size: 20px;
        }
      }
    }
  }

  &:hover {

    .swiper-button-prev,
    .swiper-button-next {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  &.loading {
    opacity: 0;
    visibility: hidden;
  }
}

.swiper-wrapper {}

.swiper-slide {
  overflow: hidden;
  @extend %backface_visibility_hidden;

  .slide-bgimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }

  .entity-img {
    display: none;
  }

  .content {
    position: absolute;
    top: 40%;
    left: 0;
    width: 50%;
    padding-left: 5%;
    color: #fff;

    .title {
      font-size: 2.6em;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .caption {
      display: block;
      font-size: 13px;
      line-height: 1.4;
      transform: translateX(50px);
      // opacity:0;
      transition: opacity .3s ease, transform .7s ease;

      &.show {
        transform: translateX(0);
        opacity: 1;
      }
    }

  }
}


[class^="swiper-button-"] {
  width: 44px;
  opacity: 1;
  visibility: visible;
  @extend %transition_all_03s;
}

.swiper-button-prev {
  transform: translateX(0);
}

.swiper-button-next {
  transform: translateX(0);
}


.swiper-meta {
  position: relative;
  
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }

  .swiper-button-next {
    color: $white;
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: -20px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
  }

  .swiper-button-prev {
    color: $white;
    position: absolute;
    top: 50%;
    left: -20px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
    margin-top: 0px;

  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: $font-size-lg;
  }
}



/*
[class^="swiper-button-"] {
  width: 44px;
  opacity: 0;
  visibility: hidden;
  @extend %transition_all_03s;
}

.swiper-button-prev {
  transform: translateX(50px);
}

.swiper-button-next {
  transform: translateX(-50px);
}
*/

@include media-breakpoint-up(lg) {

  .swiper-meta {
    position: relative;

    .swiper-button-next {
      right: -25px;
      width: 45px;
      height: 45px;
    }

    .swiper-button-prev {
      left: -25px;
      width: 45px;
      height: 45px;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: $title-lg;
    }
  }
}


// swiper-battles
// 
.swiper-battles {
  max-width: calc(100% - 40px);
  width: 100%;
  margin: 0 20px;
}

@include media-breakpoint-up(lg) {
  .swiper-battles {
    max-width: 560px;
    width: 100%;
  }
}
