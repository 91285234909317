// card-achievement

.card-achievement {
  position: relative;
  background-color: #ecf3fc;
  padding: 10px 50px 10px 20px;
  display: flex;
  border-radius: $border-radius;
  border: 1px solid $primary;
  box-shadow: $box-shadow-sm;
  min-height: 200px;

  .card-header {
    .card-title {
      font-size: $title-sm;
      line-height: $title-line-height-sm;
      letter-spacing: $title-letter-spacing-sm;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin-bottom: 10px;
    }

    .card-text {
      color: $primary;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
    }
  }

  .card-points {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: $title-sm;
    line-height: $title-line-height-sm;
    letter-spacing: $title-letter-spacing-sm;
    font-weight: $font-weight-normal;
    font-style: normal;
  }
}

.card-achievement:after {
  color: $primary;
  font-family: 'icomoon' !important;
  content: "\e972";
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: $font-size-xxl;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}


.card-achievement.disabled {
  opacity: 0.5;
}

.card-achievement.disabled {
  border-color: $white;
  background-color: $white;
}