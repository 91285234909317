// switch 

label.switch {
  margin: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  .btn.switchers {
    @include transition;
    display: inline-flex;

    .item-icon {
      span {
        text-transform: none;
      }
    }

    .item-icon:first-child {
      display: flex;
    }

    .item-icon:last-child {
      display: none;
    }
  }

  input:checked+.switchers {
    color: $secondary;

    .item-icon:first-child {
      display: none;
    }

    .item-icon:last-child {
      display: flex;
    }
  }
}


.favoritar {
  label.switch {

    .btn.switchers {
      padding: 0.375rem;

      .item-icon {
        i {
          font-size: x-large;
        }
      }
    }
  }
}


label.switch {

  input:checked+.switchers.btn-raw-secondary {
    color: $secondary;
  }

  input:focus+.switchers.btn-raw-secondary {
    box-shadow: 0 0 0 $btn-focus-width rgba($secondary, .5);
  }

  input:checked+.switchers.btn-raw-primary {
    color: $primary;
  }

  input:focus+.switchers.btn-raw-primary {
    box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
  }

  input:checked+.switchers.btn-raw-warning {
    color: $warning;
  }

  input:focus+.switchers.btn-raw-warning {
    box-shadow: 0 0 0 $btn-focus-width rgba($warning, .5);
  }
}



