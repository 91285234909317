// user-data


.user-data {
  margin-left: auto;
  max-width: 720px;

  .user-img {
    position: relative;
    width: 140px;
    height: 140px;
    margin-bottom: 30px;

    .img-profile {
      width: 100%;
      height: auto;
    }

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
    }
  }

  .user-section {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray-200;

    .user-section-title {
      color: $secondary;
      font-size: $title-sm;
      line-height: $title-line-height-sm;
      letter-spacing: $title-letter-spacing-sm;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin-bottom: 20px;
    }

    .user-name {
      color: $gray-500;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
    }

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;
    }

    .user-pw {
      .user-section-label {
        color: $gray-500;
        font-size: $font-size-base;
        line-height: $line-height-base;
        font-weight: $font-weight-normal;
        font-style: normal;
        margin: 0;
      }
    }

    .user-alerts {
      .user-section-label {
        color: $gray-500;
        font-size: $font-size-base;
        line-height: $line-height-base;
        font-weight: $font-weight-normal;
        font-style: normal;
        margin: 0;
      }

      .user-alert-state {
        color: $secondary;
        font-size: $title-xs;
        line-height: $title-line-height-xs;
        letter-spacing: $title-letter-spacing-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
        margin-bottom: 10px;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .user-data {
    margin-left: 190px;

    .user-img {
      position: relative;
      width: 140px;
      height: 140px;
      margin-bottom: 60px;

      .img-profile {
        width: 100%;
      }

      .btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .user-section {
      position: relative;

      .user-section-title {
        font-size: $title-md;
        line-height: $title-line-height-md;
        letter-spacing: $title-letter-spacing-md;
        font-weight: $font-weight-normal;
        font-style: normal;
      }

      .user-name {
        font-size: $font-size-lg;
        line-height: $line-height-sm;
        font-weight: $font-weight-normal;
      }
    }
  }
}
