// modals

.modal {
  .modal-dialog {
    .modal-content {
      position: relative;
      padding: 80px 20px 20px 20px;

      .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232175D9'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
        box-sizing: content-box;
        width: 25px;
        height: 25px;
        padding: 0.25em 0.25em;
        color: $primary;
        border: 0;
        opacity: 1;
      }

      .modal-header {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 20px;
        border: none;

        img {
          width: 48px;
          height: auto;
          margin: 0 0 10px 0;
        }

        .modal-title {
          color: $secondary;
          font-family: Graphik Web;
          font-size: $title-sm;
          line-height: $title-line-height-sm;
          letter-spacing: $title-letter-spacing-sm;
          font-weight: $font-weight-normal;
          font-style: normal;
          margin-bottom: 30px;
        }
      }

      .modal-body {
        padding: 20px;
        margin-bottom: 0;

        .modal-subtitle {
          color: $secondary;
          font-family: Graphik Web;
          font-size: $title-xs;
          line-height: $title-line-height-xxs;
          letter-spacing: $title-letter-spacing-xxs;
          font-weight: $font-weight-normal;
          font-style: normal;
        }

        .table> :not(caption)>*>* {
          padding: 0;
        }

        .search-form {
          margin-bottom: 30px;
        }

        .btns-x-2 {
          margin-top: 20px;
        }

        .modal-dialog-scrollable {
          height: calc(100vh - 600px);
          overflow: auto;
        }
      }

      .modal-footer {
        border: 0;
        padding: 0 20px;

        .btn-modal {
          width: 100%;
          padding: 18px 24px;
          font-size: $font-size-lg;
          margin: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .modal {
    .modal-dialog {
      max-width: 680px;

      .modal-content {
        padding: 80px 40px 40px 40px;

        .btn-close {
          top: 36px;
          right: 36px;
        }

        .modal-header {
          padding: 0 40px;
        }

        .modal-body {
          padding: 20px 40px;

          .img-challenge {
            width: 64px;
            height: auto;
            margin: 0 0 20px 0;
          }

          .modal-title {
            font-size: $title-md;
            line-height: $title-line-height-md;
            letter-spacing: $title-letter-spacing-md;
          }

          .modal-subtitle {
            font-size: $title-xs;
            line-height: $title-line-height-xs;
            letter-spacing: $title-letter-spacing-xs;
          }
        }

        .modal-footer {
          padding: 0 40px;
        }
      }
    }
  }
}
