// modal-edit

.modal-edit {
  .modal {
    .modal-dialog {
      .modal-content {
        padding: 80px 20px 20px 20px;
        margin-right: 0;
        margin-left: 0;

        .modal-header {
          .modal-title {
            color: $secondary;
            font-size: $title-sm;
            line-height: $title-line-height-sm;
            letter-spacing: $title-letter-spacing-sm;
            font-weight: $font-weight-normal;
            font-style: normal;
            margin-bottom: 30px;
          }
        }

        .modal-body {
          .modal-subtitle {
            color: $secondary;
            font-size: $title-sm;
            line-height: $title-line-height-sm;
            letter-spacing: $title-letter-spacing-sm;
            font-weight: $font-weight-normal;
            font-style: normal;
            margin-bottom: 20px;
          }

          p.label-title {
            color: $gray-500;
            font-family: Graphik Web;
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
            font-style: normal;
          }

          .form-floating {
            margin-bottom: 30px;

            .btn-eye-strikethrough {
              color: $primary;
              padding: 1rem 0.75rem;
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              height: 58px;
            }

            .bt-login {
              width: 100%;
              padding: 18px 24px;
              font-size: $font-size-lg;

              span {
                margin-left: 10px;
              }
            }
          }

          .password-validator {
            margin-top: -20px;
            margin-bottom: 20px;

            .label-title {
              margin: 0;
            }

            ul {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              padding: 0;

              li {
                color: $gray-500;
                margin-top: 5px;
                width: calc(50% - 10px);
                list-style: none;
              }
            }
          }

          .alerts-management {

            .form-switch {
              display: flex;
              justify-content: space-between;
              padding: 0;
              margin-bottom: 30px;
            }
          }

          .avatar-management {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}


@include media-breakpoint-up(md) {
  .modal-edit {
    .modal {
      .modal-dialog {
        .modal-content {
          padding: 80px 40px 20px 40px;
          margin-right: 40px;
          margin-left: 40px;

          .modal-header {

            .modal-title {
              font-size: $title-md;
              line-height: $title-line-height-md;
              letter-spacing: $title-letter-spacing-md;
            }

            .modal-subtitle {
              font-size: $title-md;
              line-height: $title-line-height-md;
              letter-spacing: $title-letter-spacing-md;
            }
          }

          .modal-footer {}
        }
      }
    }
  }
}
