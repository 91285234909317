// toggler

.main-nav {
  .navbar-toggler {
    position: fixed;
    border: none;
    right: 0;
    top: 0;
    padding: 20px;
    z-index: 1;

    span {
      width: 22px;
      height: 2px;
      background-color: $white;
      display: block;
      @include transition;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    span:nth-child(1) {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }
/*
  .navbar-toggler:before {
    content: "";
    background-color: $secondary;
    position: absolute;
    width: 100%;
    height:100px;
    left: 0;
    top: 0;
  }
*/
  .navbar-toggler.collapsed {
    position: absolute;

    span:nth-child(1) {
      transform: rotate(0);
    }

    span:nth-child(2) {
      opacity: 1;
    }

    span:nth-child(3) {
      transform: rotate(0);
    }
  }

  @include media-breakpoint-up(xl) {
    .navbar-toggler {
      display: none;
    }
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.navbar-toggler {}

.navbar-toggler.collapsed {}

.navbar-toggler:focus {}

.navbar-toggler:focus,
.navbar-toggler:active {}
