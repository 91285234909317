// card-challenge

.challenges {
  margin-bottom: 40px;
  .row [class*='col-'] {
    margin-bottom: 20px;
  }
}

.card.card-challenge {
  background-color: $white;
  display: flex;
  box-shadow: $box-shadow-sm;
  border: none;
  padding: 20px;
  flex-direction: column;

  .img-challenge {
    width: 64px;
    height: auto;
    margin: 0 0 20px 0;
    align-self: baseline;
  }

  .card-body {
    padding: 0;

    .card-title {
      color: $gray-500;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
    }

    .card-ctm {
      color: $primary;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
    }
  }
}

@include media-breakpoint-up(lg) {

  .card.card-challenge {
    flex-direction: row;
    height: 100%;

    .img-challenge {
      width: 64px;
      height: auto;
      margin: 10px 30px 10px 0;
    }
  }
}

