// main-header

.main-header {
  position: relative;
  top: 0;
  z-index: 10;
  width: 100%;
}

.main-header:after {
  content: "";
  display: block;
  height: 1px;
  width: calc(100% - 40px);
  position: absolute;
  bottom:0;
  background-color: $gray-500;
  left: 20px;
}


@include media-breakpoint-up(lg) {
  .main-header {
    position: absolute;
    top: 20px;
    left: 20px; 
    margin-bottom: 0;
    width: 200px;
  }
  .main-header:after {
    display: none;
  }
}
