// card-players

.card.card-players {
  background-color: transparent;
  display: flex;
  border: none;
  padding: 60px 0 60px 0;

  .card-body {
    padding: 30px 0 0 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .player {
      display: flex;
      width: 38%;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 30px 10px 10px 10px;
      border: 1px solid $white;
      background-color:#ecf3fc;
      border-radius: $border-radius;

      .profile-avatar {
        margin: 0;
        width: 50px;
        height: 50px;
        margin: 0 0 10px 0;

        img {
          width: 100%;
          height: auto;
        }
      }

      .profile-name {
        color: $secondary;
        font-size: $title-xxs;
        line-height: $title-line-height-xxs;
        font-weight: $font-weight-normal;
        margin: 0;
        width: 100%;
        text-align: center;
      }

      .player-points {
        color: $secondary;
        font-size: $title-xs;
        line-height: $title-line-height-xs;
        letter-spacing: $title-letter-spacing-xs;
        font-weight: $font-weight-normal;
        font-style: normal;
      }
    }

    .score {
      color: $secondary;
      font-size: $title-md;
      line-height: $title-line-height-md;
      letter-spacing: $title-letter-spacing-md;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin: 35px 0 0 0;
      text-align: center;
    }

    .bt-play {
      width: 100%;
      padding: 18px 24px;
      font-size: $font-size-lg;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card.card-players {
    height: 100%;

    .card-body {
      padding: 30px 0 0 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .player {
        display: flex;
        width: 38%;
        flex-direction: column;
        align-items: center;

        .profile-avatar {
          width: 100px;
          height: 100px;
        }

        .profile-name {
          font-size: $title-xs;
          line-height: $title-line-height-xs;
          letter-spacing: $title-letter-spacing-xs;
        }

        .player-points {
          font-size: $title-md;
          line-height: $title-line-height-md;
          letter-spacing: $title-letter-spacing-md;
        }
      }

      .score {
        margin: 55px 0 0 0;
        font-size: $title-md;
        line-height: $title-line-height-md;
        letter-spacing: $title-letter-spacing-md;
      }
    }
  }
}

// winner-loser

.card.card-players {
  .card-body {
    .player.player-winner {
      background-color: rgba($success, 0);
      border-radius: $border-radius;
      border: 1px solid $success;
    }

    .player.player-winner:before {
      font-family: "Font Awesome 5 Free";
      color: $white;
      position: absolute;
      content: "\f091";
      top: -16px;
      left: 50%;
      border-radius: 50%;
      font-size: $title-xxs;
      font-style: normal;
      font-weight: bold;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 1px solid $success;
      background-color: rgba($success, 1);
      width: 32px;
      height: 32px;
      text-align: center;
      margin-left: -16px;
      padding-top: 6px;
    }

    .player.player-loser {
      background-color: rgba($danger, 0);
      border-radius: $border-radius;
      border: 1px solid $danger;
    }

    .player.player-loser:before {
      font-family: 'icomoon' !important;
      color: $white;
      position: absolute;
      content: "\e922";
      top: -16px;
      left: 50%;
      border-radius: 50%;
      font-size: $title-xxs;
      font-style: normal;
      font-weight: bold;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      border: 1px solid $danger;
      background-color: rgba($danger, 1);
      width: 32px;
      height: 32px;
      text-align: center;
      margin-left: -16px;
      padding-top: 6px;
    }
  }
}

// result-text

.card.card-players + .result-text {
  margin-top: -30px;
  margin-bottom:60px
}

.result-text {
  font-size: $title-md;
  line-height: $title-line-height-md;
  letter-spacing: $title-letter-spacing-md;
  font-weight: $font-weight-normal;
  font-style: normal;
  text-align: center;

  &.result-winner {
    color: $success;
  }

  &.result-loser {
    color: $danger;
  }
}
