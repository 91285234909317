// section-header

.section-header {
  background-color: #ecf3fc;
  padding: 20px;
  display: flex;
  border-radius: $border-radius;
  margin-bottom: 50px;
  position: relative;

  img {
    width: 160px;
    padding: 0 20px;
    display: none;
  }

  .section-header-tx {
    .section-title {
      color: $secondary;
      font-size: $title-md;
      line-height: $title-line-height-md;
      letter-spacing: $title-letter-spacing-md;
      font-weight: $font-weight-normal;
      font-style: normal;
    }

    .section-subtitle {
      color: $gray-500;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin: 0;
    }
  }
}

.section-back {
  display: block;
  position: absolute;
  bottom: -60px;
  left: -10px;

  a {
    font-size: 2rem;
    color: $gray-500;
  }

  a:hover {
    font-size: 2rem;
    color: $primary;
  }
}


@include media-breakpoint-up(lg) {
  .section-header {
    padding: 30px;

    img {
      display: block;
    }

    .section-header-tx {
      .section-title {
        font-size: $title-xl;
        line-height: $title-line-height-xl;
        letter-spacing: $title-letter-spacing-xl;
      }

      .section-subtitle {
        font-size: $title-md;
        line-height: $title-line-height-md;
        letter-spacing: $title-letter-spacing-md;
      }
    }
  }

  .section-back {
    display: none;
  }
}
