// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

// basic style for copy text
//
// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_typography.scss

p {
  margin-bottom: 20px;
}

.title-xxl {
  font-size: $title-xxl;
  line-height: $title-line-height-xxl;
  letter-spacing: $title-letter-spacing-xxl;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.title-xl {
  font-size: $title-xl;
  line-height: $title-line-height-xl;
  letter-spacing: $title-letter-spacing-xl;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.title-lg {
  font-size: $title-lg;
  line-height: $title-line-height-lg;
  letter-spacing: $title-letter-spacing-lg;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.title-md {
  font-size: $title-md;
  line-height: $title-line-height-md;
  letter-spacing: $title-letter-spacing-md;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.title-sm {
  font-size: $title-sm;
  line-height: $title-line-height-sm;
  letter-spacing: $title-letter-spacing-sm;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.title-xs {
  font-size: $title-xs;
  line-height: $title-line-height-xs;
  letter-spacing: $title-letter-spacing-xs;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.title-xxs {
  font-size: $title-xxs;
  line-height: $title-line-height-xxs;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.body-lg {
  font-size: $font-size-lg;
  line-height: $line-height-sm;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.body-md {
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.body-sm {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-normal;
  font-style: normal;
}

.body-xs {
  font-size: $font-size-xs;
  line-height: $line-height-xs;
  font-weight: $font-weight-normal;
  font-style: normal;
}





@include media-breakpoint-up(lg) {
  .title-xxl {
    font-size: $title-xxl;
    line-height: $title-line-height-xxl;
    letter-spacing: $title-letter-spacing-xxl;
  }

  .title-xl {
    font-size: $title-xl;
    line-height: $title-line-height-xl;
    letter-spacing: $title-letter-spacing-xl;
  }

  .title-lg {
    font-size: $title-lg;
    line-height: $title-line-height-lg;
    letter-spacing: $title-letter-spacing-lg;
  }

  .title-md {
    font-size: $title-md;
    line-height: $title-line-height-md;
    letter-spacing: $title-letter-spacing-md;
  }

  .title-sm {
    font-size: $title-sm;
    line-height: $title-line-height-sm;
    letter-spacing: $title-letter-spacing-sm;
  }

  .title-xs {
    font-size: $title-xs;
    line-height: $title-line-height-xs;
    letter-spacing: $title-letter-spacing-xs;
  }

  .title-xxs {
    font-size: $title-xxs;
    line-height: $title-line-height-xxs;
  }

  .body-lg {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
  }

  .body-md {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }

  .body-sm {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }

  .body-xs {
    font-size: $font-size-xs;
    line-height: $line-height-xs;
  }
}
