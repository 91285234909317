// topten-nav

.topten-nav {
  padding: 0;

  .nav {
    margin: 0 0 20px 0;
    padding: 0;
    border-radius: 0;
    border-bottom: 1px solid $white;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .nav-link {
      color: #6F758C;
      font-size: $font-size-base;
      line-height: $line-height-base;
      font-weight: $font-weight-normal;
      font-style: normal;
      margin-right: 0;
      padding: 0 0 20px 0;
      border: none;
      width: 33%;
    }

    .nav-link+.nav-link {}

    .nav-link.active {
      color: $primary;
      border-bottom: 3px solid $primary;
      background-color: #ecf3fc;
    }
  }
}

@include media-breakpoint-up(sm) {
  .topten-nav {
    padding: 0 20px;

    .nav {
      justify-content: start;
      width: 100%;
      margin-left: auto;

      .nav-link {
        width: auto;
        margin-right: 20px;
      }
    }
  }
}

// tab-content

.toptens {
  .tab-content>.tab-pane {
    .topten-list {}
  }
}

@include media-breakpoint-up(xl) {
  .toptens {
    .tab-content {}

    .tab-content>.tab-pane {


      .topten-list {}
    }
  }
}
