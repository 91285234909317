// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

// @font-face declarations


@font-face {
  font-family: "Graphik Web";
  src: local("Graphik Web"), url(../../fonts/Graphik-Regular-Web.eot),
    url(../../fonts/Graphik-Regular-Web.eot?#iefix) format("embedded-opentype"),
    url(../../fonts/Graphik-Regular-Web.woff2) format("woff2"),
    url(../../fonts/Graphik-Regular-Web.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

.Graphik-Regular-Web {
  font-family: 'Graphik Web';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: randstad;
  src: local(randstad), url(../../fonts/randstad.eot?txhjs9),
    url(../../fonts/randstad.eot?txhjs9#iefix) format("embedded-opentype"),
    url(../../fonts/randstad.ttf?txhjs9) format("truetype"),
    url(../../fonts/randstad.woff?txhjs9) format("woff"),
    url(../../fonts/randstad.svg?txhjs9#randstad) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.rand-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: randstad !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1.5;
  text-transform: none;
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-executives::before {
  content: "\21";
}

.ri-money::before {
  content: "\22";
}

.ri-document-o::before {
  content: "\23";
}

.ri-pie-chart::before {
  content: "\24";
}

.ri-blank-document::before {
  content: "\25";
}

.ri-screen::before {
  content: "\26";
}

.ri-play::before {
  content: "\27";
}

.ri-details::before {
  content: "\28";
}

.ri-linkedin::before {
	content: "\29";
}

.ri-twitter::before {
	content: "\2a";
}

.ri-facebook::before {
	content: "\2b";
}

.ri-youtube::before {
	content: "\2c";
}

.ri-instagram::before {
	content: "\2d";
}

.ri-google-plus::before {
	content: "\2e";
}

.ri-down::before {
  content: "\2f";
}

.ri-arrow-right::before {
	content: "\30";
}

.ri-arrow-left::before {
	content: "\31";
}

.ri-arrow-up::before {
	content: "\32";
}

.ri-arrow-down::before {
	content: "\33";
}

.ri-star-plus::before {
  content: "\34";
}

.ri-print::before {
  content: "\35";
}

.ri-see::before {
  content: "\36";
}

.ri-countdown::before {
  content: "\37";
}

.ri-building::before {
  content: "\38";
}

.ri-workers::before {
  content: "\39";
}

/* .ri-up::before {
	content: "\3a";
} */

.ri-bar-chart::before {
  content: "\3b";
}

/* .ri-remove::before {
	content: "\41";
} */

.ri-email-send::before {
  content: "\42";
}

.ri-share::before {
  content: "\43";
}

.ri-star::before {
  content: "\44";
}

.ri-location::before {
  content: "\45";
}

.ri-target::before {
  content: "\46";
}

.ri-compass::before {
  content: "\47";
}

/* .ri-email::before {
	content: "\48";
} */

.ri-phone::before {
  content: "\49";
}

.ri-check::before {
  content: "\4a";
}

.ri-user-info::before {
  content: "\4b";
}

.ri-user-mail::before {
  content: "\4c";
}

.ri-link::before {
  content: "\4d";
}

.ri-attach::before {
  content: "\4e";
}

.ri-video::before {
  content: "\4f";
}

.ri-document-user::before {
  content: "\50";
}

.ri-list::before {
  content: "\51";
}

.ri-tachometer::before {
  content: "\52";
}

.ri-rand-wall::before {
  content: "\53";
}

.ri-directory::before {
  content: "\54";
}

.ri-paper::before {
  content: "\55";
}

.ri-computer::before {
  content: "\56";
}

.ri-info::before {
  content: "\57";
}

/* .ri-lock::before {
	content: "\59";
} */

.ri-world::before {
  content: "\5a";
}

.ri-user-executive::before {
  content: "\61";
}

.ri-user-rand::before {
  content: "\62";
}

.ri-user-menu::before {
  content: "\63";
}

.ri-clock::before {
  content: "\64";
}

.ri-document::before {
  content: "\65";
}

.ri-plus::before {
  content: "\66";
}

.ri-upload::before {
  content: "\67";
}

.ri-trash::before {
  content: "\68";
}

.ri-write-document::before {
  content: "\69";
}

.ri-document-rand::before {
  content: "\6a";
}

.ri-folder::before {
  content: "\6b";
}

.ri-graph::before {
  content: "\6c";
}

.ri-ring::before {
  content: "\6d";
}

/* .ri-user::before {
	content: "\6e";
} */

.ri-download::before {
  content: "\6f";
}

.ri-home::before {
  content: "\70";
}

.ri-inside-up::before {
  content: "\71";
}

.ri-inside-down::before {
  content: "\72";
}

.ri-question::before {
  content: "\73";
}

.ri-faq::before {
  content: "\74";
}

.ri-glass::before {
  content: "\75";
}

.ri-document-plus::before {
  content: "\76";
}

.ri-cv::before {
  content: "\77";
}

.ri-pen::before {
  content: "\78";
}

.ri-randstad::before {
  content: "\79";
}

.ri-pencil::before {
  content: "\7a";
}

.ri-quote-left::before {
  content: "\3c";
}

.ri-quote-right::before {
  content: "\3d";
}

/* /////////////////////// */

/* RESTYLING */

/* /////////////////////// */

.ri-lock::before {
  font-family: FontAwesome !important;
  content: "\f023";
}

.ri-linkedin::before {
  font-family: FontAwesome !important;
  content: "\f0e1";
}

.ri-twitter::before {
  font-family: FontAwesome !important;
  content: "\f099";
}

.ri-facebook::before {
  font-family: FontAwesome !important;
  content: "\f39e";
}

.ri-youtube::before {
  font-family: FontAwesome !important;
  content: "\f16a";
}

.ri-instagram::before {
  font-family: FontAwesome !important;
  content: "\f16d";
}

.ri-google-plus::before {
  font-family: FontAwesome !important;
  content: "\f0d5";
}

.ri-rss::before {
  font-family: FontAwesome !important;
  content: "\f09e";
}

.ri-whatsapp::before {
  font-family: FontAwesome !important;
  content: "\f232";
}

.ri-up::before {
  font-family: FontAwesome !important;
  content: "\f062";
}

.ri-arrow-right::before {
  font-family: FontAwesome !important;
  content: "\f105";
}

.ri-arrow-down::before {
  font-family: FontAwesome !important;
  content: "\f107";
}

.ri-arrow-up::before {
  font-family: FontAwesome !important;
  content: "\f106";
}

.ri-arrow-left::before {
  font-family: FontAwesome !important;
  content: "\f104";
}

.ri-email::before {
  font-family: FontAwesome !important;
  content: "\f0e0";
}

.ri-remove::before {
  font-family: FontAwesome !important;
  content: "\f00d";
}

.ri-user::before {
  font-family: FontAwesome !important;
  content: "\f2c0";
}

.ri-comment::before {
  font-family: FontAwesome !important;
  content: "\f075";
}

.ri-marker_map::before {
  font-family: FontAwesome !important;
  content: "\f041";
}
